import React from 'react'
import './styles.css'


export const ImageTextSection = (props) => {

  return (
    <div className={`image-text-${props.theme} pt-2 xs-pt-0`}>
      <div className="xs-center">
        <div>
          {props.header && <div className="grid">
            <div className="col-12 mb-3  image-text-section-center text-center">
              <h3>{props.header}</h3>
            </div>
          </div>}
          <div className="grid">
            <div className={`${props.smallImage ? "col-4_xs-12 xs-pl-3 xs-pr-3" : "col-6_xs-12"} xs-center xs-pb-2 xs-pt-2`}>
              <img
                className="image-text-section-circle-image"
                src={props.image}
              />
            </div>
            <div className={`${props.smallImage ? "col-8_xs-12" : "col-6_xs-12"} image-text-section-artist-text`}>
              <pre className="text-medium">{props.text}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
