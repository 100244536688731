import React from 'react'

import Button from '../Button'
import GtmClick from '../GtmClick'
import Link from '../Link'
import './styles.css'



export const CTASection = (props) => {
  return (
    <div className="layout-body">
      <div className="grid mt-5 pt-1 pb-1 xs-mt-3">
          <div className="col">
            <div className='hero-teaser-text text-center'>
              {props.prompt}
            </div>
            <div>
              <h2 className=" text-center">{props.heading}</h2>
            </div>
            <div className="mt-2 mb-0-5 text-center">
              <Link to="/contact">
                <GtmClick category="click" id={props.gtmTag}>
                  <Button theme="orange">
                    {props.buttonText}
                  </Button>
                </GtmClick>
              </Link>
            </div>
          </div>
        </div>
      </div>
  )
}


