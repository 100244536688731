import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const List = styled.ul`
  width: 100%;
  margin: 1.5em auto 1em auto;
`

const Tag = styled.li`
  display: inline-block;
  margin: 0 0.25em 0.25em 0;
  a {
    float: left;
    transition: 0.2s;
    background: var(--light-gray);
    font-size: 20px;
    font-weight: var(--font-weight-regular);
    ${'' /* background: ${props => props.theme.colors.tertiary}; */}
    padding: 0.5em;
    border-radius: 2px;
    text-transform: capitalize;
    text-decoration: none;
    color: var(--font-color);
    ${'' /* border: 1px solid ${props => props.theme.colors.secondary}; */}
    &:hover {
      ${'' /* background: var(--purple); */}
    }
  }
`

const TagList = props => {
  return (
    <List>
      {props.tags.map(tag => (
        <Tag key={tag.id}>
          <Link to={`/tag/${tag.slug}/`}>{tag.title}</Link>
        </Tag>
      ))}
    </List>
  )
}

export default TagList
