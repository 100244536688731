import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import NovaLayout from '../components/NovaLayout'
import Hero from '../components/Hero'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import { ImageTextSection } from '../components/ImageTextSection'

// import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import { CTASection } from '../components/CTASection'
import { BlogNewsletterForm } from '../components/BlogNewsletterForm';


const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    slug,
    heroImage,
    bodyFirst,
    bodySecond,
    publishDate,
    tags,
    author
  } = data.contentfulPost
  const postNode = data.contentfulPost

  // const previous = pageContext.prev
  // const next = pageContext.next

  return (
    <NovaLayout title={`${title} - ${config.siteTitle}`}  description="A digital agency designed for growth-driven mortgage businesses">
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Hero title={title} image={heroImage} height={'50vh'} />

      <div className="post-body">
        {tags && <TagList tags={tags} />}
        <PostDetails
          date={publishDate}
          timeToRead={bodyFirst.childMarkdownRemark.timeToRead + bodySecond.childMarkdownRemark.timeToRead}
        />
        <PageBody body={bodyFirst} />
        <BlogNewsletterForm/>
        <PageBody body={bodySecond} />
        {author && <ImageTextSection smallImage image={`https:${author.headshot.fluid.src}`} text=<div><p className="mb-1"><strong>About {author.firstName}, {author.role}</strong></p><p>{author.bio}</p></div> />}
        <div className="grid-12-center">
            <div className="hr mt-4 xs-mt-0-5" />
          </div>
      </div>
      {/* <PostLinks previous={previous} next={next} /> */}
      <CTASection 
        heading="Get a Free Assessment Now"
        prompt="Ready to grow?"
        buttonText="Get Started"
        gtaText={`gtm-blog-${title}-cta-section-get-started`}
      />
    </NovaLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      author {
        firstName
        bio
        role
        headshot {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      bodyFirst {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      bodySecond {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
