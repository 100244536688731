import React from 'react'

import HubspotForm from 'react-hubspot-form'
import './styles.css'



export const BlogNewsletterForm = () => {
  return (
    <div className="layout-body">
      <div className="grid mt-2 pt-1 pb-1 xs-mt-1">
          <div className="col">
            <h5 className="text-center mb-2 newsletter-form-header">Join other growth-driven businesses and get the latest in inbound strategy delivered to your inbox.</h5>
            <HubspotForm
              portalId='6296607'
              formId='4fa4bcdf-aa9a-4076-b4cb-b3dbd7ba18ad'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </div>
        </div>
      </div>
  )
}
